import React, { FunctionComponent, ReactElement, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { ThemedProps } from '../styles/theme';

interface ModalProps extends ThemedProps {
    isOpen: boolean;
}

const ModalContainer = styled.div<ModalProps>`
    overflow: auto;
    top: ${({ isOpen }: ModalProps) => (isOpen ? '0' : '-100vh  ')};
    left: 0;
    opacity: ${({ isOpen }: ModalProps) => (isOpen ? '1' : '0')};
    min-width: 100vw;
    max-width: 100vw;
    min-height: 100vh;
    max-height: 100vh;
    position: fixed;
    background-color: rgb(84, 84, 84, 0.8);
    display: grid;
    place-items: center;
    transition: all 0.3s ease-in-out;
    z-index: 10000;
`;

const ModalContent = styled.div<ThemedProps>``;

const MODAL_CONTAINER_ID = 'modal_container_id';

interface Props {
    isOpen: boolean;
    handleSetIsOpen: () => void;
    children: ReactElement;
}

const Modal: FunctionComponent<Props> = ({ isOpen, handleSetIsOpen, children }) => {
    const modalContainerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const current = modalContainerRef.current;
        const handleClickOutside = (event: any) => {
            if (event.target.id === MODAL_CONTAINER_ID && isOpen) {
                handleSetIsOpen();
            }
        };

        if (current) current.addEventListener('mousedown', handleClickOutside);
        return () => current?.removeEventListener('mousedown', handleClickOutside);
    }, [modalContainerRef.current]);

    return (
        <ModalContainer id={MODAL_CONTAINER_ID} ref={modalContainerRef} isOpen={isOpen}>
            <ModalContent>{children}</ModalContent>
        </ModalContainer>
    );
};

export default Modal;
