import React, { FunctionComponent } from 'react';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
import { ThemedProps } from '../styles/theme';

const ItemDescriptionContentContainer = styled.div`
    padding: 1rem;
    * {
        font-size: ${({ theme }: ThemedProps) => theme.typography.sizes.normal};
    }
`;

const Container = styled.div<ThemedProps>`
    background-color: ${({ theme }: ThemedProps) => theme.colors.white};
    border-radius: ${({ theme }: ThemedProps) => theme.borders.radius};
    border: 1px solid ${({ theme }: ThemedProps) => theme.colors.gray};
`;

interface Props {
    description: string;
}

const ItemDescription: FunctionComponent<Props> = ({ description }) => (
    <Container>
        <ItemDescriptionContentContainer>{ReactHtmlParser(description)}</ItemDescriptionContentContainer>
    </Container>
);

export default ItemDescription;
