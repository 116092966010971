import React, { FunctionComponent, useState } from 'react';
import LayoutWithProtection from '../components/layout/layout-with-protection';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { ThemedProps } from '../styles/theme';
import { LinkTo, LocationLinks } from '../components/location-links';
import { DISCOUNT_INDICATOR_TITLE, NEW_INDICATOR_TITLE } from '../common';
import ItemDescription from '../components/item-description';
import PageContainer from '../components/page-container';
import { Asset } from '../generated/graphql';
import ImagesGallery from '../components/images-gallery';
import { checkIfProductIsNew, formatPrice } from '../utils/utils';
import Indicator from '../components/indicator';
import AdvertisesBar from '../components/advertises-bar';
import AspectRatioImage from '../components/aspect-ratio-image';

const ProductContentContainer = styled.div`
    display: grid;
    gap: 1rem;
    grid-template-columns: minmax(300px, 30%) 1fr;

    ${({ theme }: ThemedProps) => theme.mediaQueries.mobile} {
        grid-template-columns: unset;
    }
`;

const ProductContainer = styled(PageContainer).attrs({ paddingVersion: 'large' })`
    flex-flow: column wrap;
`;

const TextContainer = styled.div`
    display: grid;
    gap: 1rem;
    grid-auto-rows: min-content;
`;

const ProductName = styled.h1<ThemedProps>`
    margin: 0;
    font-size: ${({ theme }: ThemedProps) => theme.typography.sizes.title};
`;

const ProductCurrentPrice = styled.h2<ThemedProps>`
    margin: 0;
    color: ${({ theme }: ThemedProps) => theme.colors.yellow};
    font-weight: ${({ theme }: ThemedProps) => theme.typography.weights.bold};
`;

const ProductOldPrice = styled.h3<ThemedProps>`
    margin: 0;
    color: ${({ theme }: ThemedProps) => theme.colors.darkgray};
    font-size: ${({ theme }: ThemedProps) => theme.typography.sizes.normal};
    white-space: nowrap;
`;

const ProductSavedMoney = styled.h4<ThemedProps>`
    margin: 0;
    font-size: ${({ theme }: ThemedProps) => theme.typography.sizes.small};
    white-space: nowrap;
`;

const ProductPriceContainer = styled.div`
    display: flex;
    flex-flow: column;
    margin-left: 4em;
    text-align: right;
`;

const SecondaryImagesContainer = styled.div<ThemedProps>`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-auto-rows: minmax(100px, 1fr);
    gap: 1rem;

    ${({ theme }: ThemedProps) => theme.mediaQueries.mobile} {
        grid-template-columns: repeat(auto-fill, minmax(75px, 1fr));
        grid-auto-rows: minmax(75px, 1fr);
    }
`;

const ProductImageContainer = styled.div.attrs<ThemedProps>({
    tabIndex: 0,
    role: 'button',
})`
    width: 100%;
    height: auto;
    position: relative;
    text-align: center;
    cursor: pointer;
    background-color: ${({ theme }: ThemedProps) => theme.colors.white};
    border-radius: ${({ theme }: ThemedProps) => theme.borders.radius};
    border: 1px solid ${({ theme }: ThemedProps) => theme.colors.gray};
    transition: box-shadow 0.2s ease-in-out;
    overflow: hidden;
    margin-bottom: 1rem;

    &:hover {
        box-shadow: ${({ theme }: ThemedProps) => theme.effects.shadow};
    }

    &:focus {
        box-shadow: ${({ theme }: ThemedProps) => theme.effects.shadow};
    }
`;

const IndicatorsContainer = styled.div`
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    right: 0.5rem;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & > div {
        margin-bottom: 0.5rem;
    }
`;

const ItemNameAndPrice = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    background-color: ${({ theme }: ThemedProps) => theme.colors.white};
    border-radius: ${({ theme }: ThemedProps) => theme.borders.radius};
    border: 1px solid ${({ theme }: ThemedProps) => theme.colors.gray};
    padding: 1rem;
`;

interface BaseCategory {
    id: string;
    name: string;
}

const resolveLinksTo = (category: BaseCategory, subcategory: BaseCategory): LinkTo[] => {
    return [
        { href: '/catalog', name: 'Katalog' },
        { href: `/catalog?categoryId=${category.id}`, name: category.name },
        { href: `/catalog?categoryId=${category.id}&subcategoryId=${subcategory.id}`, name: subcategory.name },
    ];
};

const Product: FunctionComponent<any> = ({
    data: {
        cms: { product, advertises },
    },
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalImageUrl, setModalImageUrl] = useState('');
    const secondaryImagesUrls = product.images.map(({ url }: Asset) => url);
    const allImagesUrls = [product.mainImage.url, ...secondaryImagesUrls];

    const handleSetModalOpenAndUrl = (url: string) => {
        setModalImageUrl(url);
        setIsModalOpen((prevState) => !prevState);
    };

    const handleSetModalImageUrl = (url: string) => {
        setModalImageUrl(url);
    };

    const handleSetModalOpen = () => {
        setIsModalOpen((prevState) => !prevState);
    };

    const category = {
        name: product.subcategory.category.name,
        id: product.subcategory.category.id,
    };

    const subcategory = {
        name: product.subcategory.name,
        id: product.subcategory.id,
    };

    return (
        <LayoutWithProtection>
            <ProductContainer>
                <AdvertisesBar advertises={advertises} />
                <LocationLinks linksTo={resolveLinksTo(category, subcategory)} />
                <ProductContentContainer>
                    <div>
                        <ProductImageContainer onClick={() => handleSetModalOpenAndUrl(product.mainImage.url)}>
                            {(product.latest || checkIfProductIsNew(product.createdAt)) && (
                                <IndicatorsContainer>
                                    <Indicator title={NEW_INDICATOR_TITLE} />
                                    <Indicator title={DISCOUNT_INDICATOR_TITLE} />
                                </IndicatorsContainer>
                            )}
                            <AspectRatioImage
                                ratioWidth={3}
                                ratioHeight={4}
                                src={product.mainImage.url}
                                alt={product.mainImage.url}
                            />
                        </ProductImageContainer>
                        <SecondaryImagesContainer>
                            {secondaryImagesUrls.map((url: string, i: number) => (
                                <ProductImageContainer
                                    key={`${url}_${i}`}
                                    onClick={() => handleSetModalOpenAndUrl(url)}
                                >
                                    <AspectRatioImage ratioWidth={3} ratioHeight={4} src={url} alt={url} />
                                </ProductImageContainer>
                            ))}
                        </SecondaryImagesContainer>
                    </div>
                    <TextContainer>
                        <ItemNameAndPrice>
                            <ProductName>{product.name}</ProductName>
                            <ProductPriceContainer>
                                {product.oldPrice && (
                                    <ProductOldPrice>Cena za 1 szt. - {formatPrice(product.oldPrice)}</ProductOldPrice>
                                )}
                                <ProductCurrentPrice>{formatPrice(product.price)}</ProductCurrentPrice>
                                {product.oldPrice && (
                                    <ProductSavedMoney>
                                        Cena za 1 szt. przy zakupie 4
                                    </ProductSavedMoney>
                                )}
                            </ProductPriceContainer>
                        </ItemNameAndPrice>
                        <ItemDescription description={product.description.html} />
                    </TextContainer>
                </ProductContentContainer>
            </ProductContainer>
            <ImagesGallery
                currentImageUrl={modalImageUrl}
                handleSetCurrentImageUrl={handleSetModalImageUrl}
                imagesUrls={allImagesUrls}
                isGalleryOpen={isModalOpen}
                handleSetIsGalleryOpen={handleSetModalOpen}
            />
        </LayoutWithProtection>
    );
};

export const query = graphql`
    query product($id: ID!) {
        cms {
            product(where: { id: $id }) {
                id
                name
                price
                oldPrice
                latest
                subcategory {
                    id
                    name
                    category {
                        id
                        name
                    }
                }
                description {
                    html
                }
                createdAt
                mainImage {
                    url
                }
                images {
                    url
                }
            }
            advertises {
                id
                url
                image {
                    url
                }
            }
        }
    }
`;

export default Product;
