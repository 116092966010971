import React, { FunctionComponent, useEffect, useState } from 'react';
import Modal from './modal';
import styled from 'styled-components';
import { ThemedProps } from '../styles/theme';
import IconArrow from '../static/icons/icon-arrow.inline.svg';
import IconClose from '../static/icons/icon-close.inline.svg';
import { lockGlobalScroll } from '../utils/utils';

interface IconArrowsProps extends ThemedProps {
    disabled: boolean;
}

const CustomIconClose = styled(IconClose).attrs<ThemedProps>({
    tabIndex: 0,
    role: 'button',
})`
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    top: 1rem;
    right: 1rem;
    position: absolute;
    fill: ${({ theme }: ThemedProps) => theme.colors.gray};
    transition: fill 0.3s ease-in-out;
`;

const CustomIconArrow = styled(IconArrow).attrs<IconArrowsProps>({
    tabIndex: 0,
    role: 'button',
})`
    display: ${({ disabled }: IconArrowsProps) => (disabled ? 'none' : 'block')};

    top: 50%;
    position: absolute;
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    fill: ${({ theme }: IconArrowsProps) => theme.colors.gray};
    transition: fill 0.3s ease-in-out;
`;

const CustomLeftIconArrow = styled(CustomIconArrow)`
    left: 1rem;
    transform: rotate(90deg);
`;

const CustomRightIconArrow = styled(CustomIconArrow)`
    right: 1rem;
    transform: rotate(-90deg);
`;

const GalleryImageContainer = styled.div`
    position: relative;
    overflow: hidden;
    display: flex;
    border-radius: ${({ theme }: ThemedProps) => theme.borders.radius};
    box-shadow: ${({ theme }: ThemedProps) => theme.effects.shadow};
    margin: auto;

    &:hover {
        ${CustomIconClose},
        ${CustomIconArrow} {
            fill: ${({ theme }: ThemedProps) => theme.colors.darkgray};
        }
    }
`;

const GalleryImage = styled.img<ThemedProps>`
    max-width: 90vw;
    max-height: 90vh;
    background-color: ${({ theme }: ThemedProps) => theme.colors.white};
`;

const resolveIterationInBothSides = (indicator: number, currentUrlIndex: number, urlsNumber: number) => {
    const possibleIndexIndicator = currentUrlIndex + indicator;
    return possibleIndexIndicator >= 0
        ? possibleIndexIndicator % urlsNumber
        : (urlsNumber + possibleIndexIndicator) % urlsNumber;
};

interface Props {
    isGalleryOpen: boolean;
    handleSetIsGalleryOpen: () => void;
    currentImageUrl: string;
    handleSetCurrentImageUrl: (url: string) => void;
    imagesUrls: string[];
}

const ImagesGallery: FunctionComponent<Props> = ({
    isGalleryOpen,
    handleSetIsGalleryOpen,
    currentImageUrl,
    handleSetCurrentImageUrl,
    imagesUrls,
}) => {
    useEffect(() => {
        lockGlobalScroll(isGalleryOpen);
    }, [isGalleryOpen]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeysDown);
        return () => document.removeEventListener('keydown', handleKeysDown);
    });

    const handleKeysDown = (e: KeyboardEvent) => {
        if (isGalleryOpen) {
            if (e.key === 'Escape') handleSetIsGalleryOpen();
            if (e.key === 'ArrowRight') handleChangeDisplayedImage(1);
            if (e.key === 'ArrowLeft') handleChangeDisplayedImage(-1);
        }
    };

    const urlsNumber = imagesUrls.length;
    const [currentUrlIndex, setCurrentUrlIndex] = useState(imagesUrls.indexOf(currentImageUrl));

    const arrowsDisabled = urlsNumber < 2;

    const handleChangeDisplayedImage = (indicator: number) => {
        const newImageIndex = resolveIterationInBothSides(indicator, currentUrlIndex, urlsNumber);
        const newImageUrl = imagesUrls[newImageIndex];
        setCurrentUrlIndex(newImageIndex);
        handleSetCurrentImageUrl(newImageUrl);
    };

    return (
        <Modal isOpen={isGalleryOpen} handleSetIsOpen={handleSetIsGalleryOpen}>
            <GalleryImageContainer>
                <CustomIconClose onClick={handleSetIsGalleryOpen} />
                <CustomLeftIconArrow onClick={() => handleChangeDisplayedImage(-1)} disabled={arrowsDisabled} />
                <GalleryImage src={currentImageUrl} />
                <CustomRightIconArrow onClick={() => handleChangeDisplayedImage(1)} disabled={arrowsDisabled} />
            </GalleryImageContainer>
        </Modal>
    );
};

export default ImagesGallery;
