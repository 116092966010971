import React, { FunctionComponent } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { ThemedProps } from '../styles/theme';
import IconArrow from '../static/icons/icon-arrow.inline.svg';

const LinksContainer = styled.div<ThemedProps>`
    display: flex;
`;

const LinkContainer = styled.div<ThemedProps>`
    display: flex;
    align-items: center;
    margin: 1rem 0;

    & > a {
        text-decoration: none;
        color: inherit;
        &:hover {
            color: ${({ theme }: ThemedProps) => theme.colors.darkgray};
        }
    }
`;

const LinkName = styled.h4`
    margin: 0;
`;

const CustomIconArrow = styled(IconArrow)<ThemedProps>`
    width: 1em;
    height: 1em;
    transform: rotate(-90deg);
    fill: ${({ theme }: ThemedProps) => theme.colors.black};
`;

interface LinkTo {
    href: string;
    name: string;
}

interface Props {
    linksTo: LinkTo[];
}

const LocationLinks: FunctionComponent<Props> = ({ linksTo }) => {
    return (
        <LinksContainer>
            {linksTo.map((linkTo, i) => (
                <LinkContainer key={linkTo.href}>
                    <Link to={linkTo.href}>
                        <LinkName>{linkTo.name}</LinkName>
                    </Link>
                    {i < linksTo.length - 1 ? <CustomIconArrow /> : null}
                </LinkContainer>
            ))}
        </LinksContainer>
    );
};

export { LocationLinks };
export type { LinkTo };
